import request from '@/utils/request'

// 文章分类
export function getTreeRequest(params) {
  return request({
    url: '/admin/SysArticleType/GetTree',
    method: 'get',
    params
  })
}

// 修改状态
export function setStatesRequest(params) {
  return request({
    url: '/admin/SysArticleType/setStates',
    method: 'get',
    params
  })
}

// 添加分类
export function addtypeRequest(data) {
  return request({
    url: '/admin/SysArticleType/add',
    method: 'post',
    data
  })
}

// 级联选择列表
export function getSelectRequest(params) {
  return request({
    url: '/admin/SysArticleType/GetSelect',
    method: 'get',
    params
  })
}

// 获取分类详情
export function getdatilRequest(params) {
  return request({
    url: '/admin/SysArticleType/getdatil',
    method: 'get',
    params
  })
}

// 删除分类
export function deltypeRequest(params) {
  return request({
    url: '/admin/SysArticleType/del',
    method: 'get',
    params
  })
}

// 修改分类
export function edittypeRequest(data) {
  return request({
    url: '/admin/SysArticleType/edit',
    method: 'post',
    data
  })
}
// 设置状排序
export function setSortRequest(params) {
  return request({
    url: '/admin/SysArticleType/setSort',
    method: 'get',
    params
  })
}